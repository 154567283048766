import Container from "./components/Container";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ContainerMiddle from "./components/ContainerMiddle";


function App() {
  return (
    <Container>
      <Header >

      </Header>
      <ContainerMiddle>
      
      </ContainerMiddle>
      <Footer>

      </Footer>
    </Container>
  )
}

export default App;
